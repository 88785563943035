/** 记录列表 */
export const dqCheckLineTableColumns = [
    { title: "拍品名称", align: "center", scopedSlots: {customRender: 'itemProductNameSlot'} },
    { title: "送评类型", align: "center", scopedSlots: {customRender: 'itemOrderRateServicePayTypeSlot'}, width: 150 },
    { title: "订单号/D类送评单号", align: "center", scopedSlots: { customRender: "itemOrderNoSlot" }, width: 200 },
    { title: "质检状态", align: "center", scopedSlots: { customRender: "itemCheckStatusSlot" }},
    { title: "质检结果", align: "center", scopedSlots: { customRender: "itemCheckResultSlot" }},
    { title: "是否到达", align: "center", scopedSlots: { customRender: "itemArriveSlot" }},
    { title: "产品图片", align: "center", scopedSlots: { customRender: "itemAuctionImageSlot" }, width: 250 },
    { title: "质检图片", align: "center", scopedSlots: { customRender: "itemCheckAuctionImageSlot" }, width: 250 },
    { title: "买家是否需要", align: "center", scopedSlots: { customRender: "itemBuyerNeedOrNotNeedSlot" }, width: 250 },
    { title: "送评用户", align: "center", scopedSlots: { customRender: "itemRateUserNameOrIdSlot" } },
    { title: "质检描述", align: "center", scopedSlots: { customRender: 'itemReviewRemarksSlot' }, width: 150 },
    { title: "质检人", align: "center", dataIndex: 'sysAdmin' },
    { title: "质检时间", align: "center", dataIndex: 'qualityTime' },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" }, width: 200 },
]

