var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemOrderNoSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.orderRatingServiceRank === "D"
                  ? _c("span", { staticClass: "color-blue" }, [
                      _vm._v(_vm._s(row.orderNo))
                    ])
                  : _c(
                      "span",
                      {
                        staticClass: "cur-pot color-blue",
                        on: {
                          click: function($event) {
                            return _vm.handleGoToOrder(row.orderNo)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.orderNo))]
                    )
              ])
            }
          },
          {
            key: "itemOrderRateServicePayTypeSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-tooltip",
                    [
                      _c("template", { slot: "title" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.computedRateServicePayTypeTextByDesc(
                                row.orderRatingServiceRank
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "span",
                        {
                          class:
                            _vm.dqCheckPayClassType[row.orderRatingServiceRank]
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.computedRateServicePayTypeText(
                                row.orderRatingServiceRank
                              )
                            )
                          )
                        ]
                      )
                    ],
                    2
                  )
                ],
                1
              )
            }
          },
          {
            key: "itemProductNameSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", [
                  _vm._v(
                    _vm._s(
                      (row.productName || "斗泉渠道代理") +
                        (row.versionName ? row.versionName : "")
                    )
                  )
                ])
              ])
            }
          },
          {
            key: "itemCheckStatusSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  { class: _vm.dqCheckStatusClassType[row.reviewStatus] },
                  [_vm._v(_vm._s(_vm.computedStatusTypeText(row.reviewStatus)))]
                )
              ])
            }
          },
          {
            key: "itemCheckResultSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  {
                    class:
                      _vm.dqCheckResultClassType[row.responsibilityDetermine]
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.computedResultTypeText(row.responsibilityDetermine)
                      )
                    )
                  ]
                )
              ])
            }
          },
          {
            key: "itemArriveSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  {
                    class: _vm.dqArriveClassType.get(row.receiveConfirmStatus)
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.computedArriveTypeText(row.receiveConfirmStatus)
                      )
                    )
                  ]
                )
              ])
            }
          },
          {
            key: "itemAuctionImageSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.productImageList
                  ? _c(
                      "div",
                      { staticClass: "img-list" },
                      _vm._l(row.productImageList, function(item, index) {
                        return _c("img", {
                          key: index,
                          attrs: {
                            src: _vm.computedNotShowImage(item),
                            alt: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.handlePreviewImg(
                                row.productImageList,
                                index
                              )
                            }
                          }
                        })
                      }),
                      0
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemCheckAuctionImageSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.items
                  ? _c(
                      "div",
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "link" },
                            on: {
                              click: function($event) {
                                return _vm.handleShowMoreCheckImages(row.items)
                              }
                            }
                          },
                          [_vm._v("查看更多质检图片")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                row.confirmReceiptImage
                  ? _c(
                      "div",
                      { staticClass: "img-list" },
                      _vm._l(row.confirmReceiptImage, function(item, index) {
                        return _c("img", {
                          key: index,
                          attrs: {
                            src: _vm.computedNotShowImage(item),
                            alt: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.handlePreviewImg(
                                row.confirmReceiptImage,
                                index
                              )
                            }
                          }
                        })
                      }),
                      0
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemBuyerNeedOrNotNeedSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.orderRatingServiceRank === "D"
                  ? _c("span", [_vm._v("-")])
                  : _c(
                      "span",
                      {
                        class:
                          _vm.dqCheckResultAfterByBuyerClassType[
                            row.buyerSelectionAfterInitialReview
                          ]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.computedResultAfterByBuyerTypeText(
                              row.buyerSelectionAfterInitialReview
                            ) || "未选择"
                          )
                        )
                      ]
                    )
              ])
            }
          },
          {
            key: "itemRateUserNameOrIdSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", [_vm._v(_vm._s(row.ratingUserId))]),
                _c("div", [_vm._v(_vm._s(row.ratingUserName))])
              ])
            }
          },
          {
            key: "itemReviewRemarksSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  row.reviewRemarks
                    ? _c(
                        "a-popover",
                        [
                          _c("template", { slot: "content" }, [
                            _c("p", [
                              _vm._v(_vm._s(row.reviewRemarks || "暂无描述"))
                            ])
                          ]),
                          _c("a-button", { attrs: { type: "link" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.reviewRemarks.length > 8
                                    ? row.reviewRemarks.slice(0, 8) + "..."
                                    : row.reviewRemarks
                                )
                            )
                          ])
                        ],
                        2
                      )
                    : _c("span", [_vm._v("暂无描述")])
                ],
                1
              )
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "mt-10",
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleShowLogisticsInfo(row)
                        }
                      }
                    },
                    [_vm._v("查看物流信息")]
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c("LogisticsInfoPopup", { ref: "logisticsInfoPopupEl" }),
      _c("ShowMoreImages", { ref: "showMoreImagesEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }