<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   订单   -->
      <div slot="itemOrderNoSlot" slot-scope="row">
        <span v-if="row.orderRatingServiceRank === 'D'" class="color-blue">{{ row.orderNo }}</span>
        <span v-else @click="handleGoToOrder(row.orderNo)" class="cur-pot color-blue">{{ row.orderNo }}</span>
      </div>
<!--   送评类型   -->
      <div slot="itemOrderRateServicePayTypeSlot" slot-scope="row">
        <a-tooltip>
          <template slot="title">
            {{ computedRateServicePayTypeTextByDesc(row.orderRatingServiceRank) }}
          </template>
          <span :class="dqCheckPayClassType[row.orderRatingServiceRank]">{{ computedRateServicePayTypeText(row.orderRatingServiceRank) }}</span>
        </a-tooltip>
      </div>
<!--   产品相关   -->
      <div slot="itemProductNameSlot" slot-scope="row">
        <div>{{ (row.productName || '斗泉渠道代理') + (row.versionName ? row.versionName : '') }}</div>
      </div>
<!--   结果状态   -->
      <div slot="itemCheckStatusSlot" slot-scope="row">
        <span :class="dqCheckStatusClassType[row.reviewStatus]">{{ computedStatusTypeText(row.reviewStatus) }}</span>
      </div>
<!--   结果类型   -->
      <div slot="itemCheckResultSlot" slot-scope="row">
        <span :class="dqCheckResultClassType[row.responsibilityDetermine]">{{ computedResultTypeText(row.responsibilityDetermine) }}</span>
      </div>
<!--   结果类型   -->
      <div slot="itemArriveSlot" slot-scope="row">
        <span :class="dqArriveClassType.get(row.receiveConfirmStatus)">{{ computedArriveTypeText(row.receiveConfirmStatus) }}</span>
      </div>
<!--   拍品图片列表   -->
      <div slot="itemAuctionImageSlot" slot-scope="row">
        <div class="img-list" v-if="row.productImageList">
          <img
              v-for="(item, index) in row.productImageList"
              :key="index"
              :src="computedNotShowImage(item)"
              alt=""
              @click="handlePreviewImg(row.productImageList, index)"
          >
        </div>
      </div>
<!--  质检图片    -->
      <div slot="itemCheckAuctionImageSlot" slot-scope="row">
        <div v-if="row.items"><a-button type="link" @click="handleShowMoreCheckImages(row.items)">查看更多质检图片</a-button></div>
        <div class="img-list" v-if="row.confirmReceiptImage">
          <img
              v-for="(item, index) in row.confirmReceiptImage"
              :key="index"
              :src="computedNotShowImage(item)"
              alt=""
              @click="handlePreviewImg(row.confirmReceiptImage, index)"
          >
        </div>
      </div>
<!--  显示买家是否需要    -->
      <div slot="itemBuyerNeedOrNotNeedSlot" slot-scope="row">
        <span v-if="row.orderRatingServiceRank === 'D'">-</span>
        <span
            v-else
            :class="dqCheckResultAfterByBuyerClassType[row.buyerSelectionAfterInitialReview]"
        >{{ computedResultAfterByBuyerTypeText(row.buyerSelectionAfterInitialReview) || '未选择' }}</span>
      </div>
<!--   送评用户   -->
      <div slot="itemRateUserNameOrIdSlot" slot-scope="row">
        <div>{{ row.ratingUserId }}</div>
        <div>{{ row.ratingUserName }}</div>
      </div>
<!--  显示操作    -->
      <div slot="itemReviewRemarksSlot" slot-scope="row">
        <a-popover  v-if="row.reviewRemarks">
          <template slot="content">
           <p>{{ row.reviewRemarks || '暂无描述' }}</p>
          </template>
          <a-button
              type="link"
          > {{ row.reviewRemarks.length > 8 ? row.reviewRemarks.slice(0, 8) + '...' : row.reviewRemarks  }}</a-button>
        </a-popover>
        <span v-else>暂无描述</span>
      </div>
<!--  操作    -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-button
            class="mt-10"
            size="small"
            type="primary"
            @click="handleShowLogisticsInfo(row)"
        >查看物流信息</a-button>
      </div>
    </a-table>
<!--  展示物流信息  -->
    <LogisticsInfoPopup ref="logisticsInfoPopupEl"/>
<!--  展示D类送评图片列表  -->
    <ShowMoreImages ref="showMoreImagesEl"/>
  </div>
</template>
<script>
import {dqCheckLineTableColumns} from "@/views/cmsPage/checkManage/recordlist/_data"
import {
  computedArriveClassType,
  computedResultAfterByBuyerType,
  computedResultType, computedStatusType, dqArriveClassType, dqCheckPayClassType, dqCheckResultAfterByBuyerClassType,
  dqCheckResultAfterByBuyerTypeList,
  dqCheckResultClassType, dqCheckStatusClassType, rateServicePayTypeMapText, rateServicePayTypeMapTextByDesc
} from "@/views/cmsPage/checkManage/_data"
import LogisticsInfoPopup from "@/views/cmsPage/checkManage/recordlist/_components/LogisticsInfoPopup"
import {addStringIfContains} from "@/untils/otherEvent"
import ShowMoreImages from "@/views/cmsPage/checkManage/operate/_components/ShowMoreImages"

export default {
  props: ['pagination', 'list'],
  components: {LogisticsInfoPopup, ShowMoreImages},
  data() {
    return {
      dqArriveClassType,
      tableList: dqCheckLineTableColumns,
      dqCheckResultClassType,
      dqCheckStatusClassType,
      dqCheckResultAfterByBuyerTypeList,
      dqCheckResultAfterByBuyerClassType,
      dqCheckPayClassType,
    }
  },
  computed: {
    computedNotShowImage() {
      return (value) => addStringIfContains(value, 'x-oss', '?x-oss-process=image/resize,p_100/format,jpg')
    },
    computedResultTypeText() {
      return (value) => computedResultType(value)
    },
    computedRateServicePayTypeTextByDesc() {
      return (value) => rateServicePayTypeMapTextByDesc(value)
    },
    computedRateServicePayTypeText() {
      return (value) => rateServicePayTypeMapText(value)
    },
    computedStatusTypeText() {
      return (value) => computedStatusType(value)
    },
    computedResultAfterByBuyerTypeText() {
      return (value) => computedResultAfterByBuyerType(value)
    },
    computedArriveTypeText() {
      return (value) => computedArriveClassType(value)
    }
  },
  methods: {
    /** 查看更多拍品图片 */
    handleShowMoreCheckImages(images) {
      this.$refs.showMoreImagesEl.showPopup(images)
    },
    /** 去往订单页面 */
    handleGoToOrder(orderNo) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqplatform/ordermannage?orderNo=${orderNo}`;
      window.open(url)
    },
    /** 查看物流信息 */
    handleShowLogisticsInfo(row) {
      if (!row.sellerLogisticsId) return this.$message.warn('当前没有物流ID')
      this.$refs.logisticsInfoPopupEl.showPopup(row.sellerLogisticsId)
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    handlePreviewImg(images, index) {
      const urls = images.map(el => {
        return {img_url: el}
      })
      this.$previewImg({
        list: urls,
        baseImgField: "img_url",
        showMute: false,
        current: index
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.color-green {
  color: green;
}
.img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50px;
  }
}
</style>